<template>
    <leisure-module-component></leisure-module-component>
</template>

<script>
    import LeisureModuleComponent from "@/components/admin/modules/LeisureModuleComponent";

    export default {
        name: "LeisuresModule",
        title: "Gestión de Entetenimientos | Turismo BC",
        components: {LeisureModuleComponent}
    }
</script>

<style scoped>

</style>